import request from "./request";
// 产品中心-追溯-列表
export function mgtProductFromList(data) {
    return request({
        url: "/mgtProductFrom/list",
        method: "get",
        data,
    }).then(res => {
        return res.data
    });
}
// 业务-产品中心-追溯-平台
export function mgtProductFromPlateList(data) {
    return request({
        url: "/mgtProductFromPlate/list",
        method: "post",
        data,
    }).then(res => {
        return res.data
    });
}
// 业务-产品中心-追溯-平台_详情
export function mgtProductFromPlateForm(data) {
    return request({
        url: "/mgtProductFromPlate/form",
        method: "post",
        data,
    }).then(res => {
        return res.data
    });
}
// 业务-产品中心-追溯-应用
export function mgtProductFromApidList(data) {
    return request({
        url: "/mgtProductFromApid/list",
        method: "post",
        data,
    }).then(res => {
        return res.data
    });
}
// 业务-产品中心-追溯-场景
export function mgtProductFromSceneList(data) {
    return request({
        url: "/mgtProductFromScene/list",
        method: "post",
        data,
    }).then(res => {
        return res.data
    });
}