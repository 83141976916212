<template>
  <div style="height: 379px">
    <el-amap
      vid="amapDemo"
      :center="center"
      :zoom="zoom"
      :zoomEnable="zoomEnable"
      class="amap-demo"
    >
      <el-amap-marker
        :position="marker.position"
        :visible="marker.visible"
        :draggable="marker.draggable"
      >
      </el-amap-marker>
      <!-- 位置名称显示 -->
      <el-amap-text
        :text="marker.text"
        :offset="marker.offset"
        :position="marker.position"
      />
      <el-amap-info-window
        v-if="window"
        :position="window.position"
        :content="window.content"
        :offset="window.offset"
      ></el-amap-info-window>
    </el-amap>
  </div>
</template>
<script>
export default {
  name: "Map",
  props: {
    selAddr: {
      type: String,
      default: "",
    },
    lon: {
      type: String,
      default: "",
    },
    lat: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // location:location,
      zoom: 13,
      center: [+this.lon, +this.lat],
      zoomEnable: false,
      marker: {
        position: [+this.lon, +this.lat],
        text: this.selAddr,
        offset: [0, 30],
      },
      windows: {},
      window: {
        content:
          "<div><p style='font-size: 18px;font-family: Microsoft YaHei;font-weight: 400;color: #000000;line-height: 26px;margin-bottom: 8px;'>公司地址</p><p style='font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;  line-height: 20px;margin-bottom: 4px;'>" +
          this.selAddr +
          "</p></div>",
        offset: [0, 30],
        position: [+this.lon, +this.lat],
        visible: true,
      },
    };
  },
  created() {
    console.log(+this.lon, +this.lat);
  },
};
</script>
<style scoped lang="less">
/deep/ .amap-overlay-text-container {
  font-size: 12px;
  color: #979595;
}
/deep/ .amap-info-contentContainer {
  position: absolute;
  bottom: -26px;
  left: 24px;
}
/deep/ .amap-info-contentContainer {
  padding-bottom: 70px;
}
/deep/ .bottom-center .amap-info-sharp {
  bottom: 63px;
}
/deep/ .amap-info-content {
  border: 1px solid #e4e3e2;
  width: 238px;
}
/deep/ .amap-info-close {
  display: none !important;
}
/deep/ .amap-marker {
  left: 0 !important;
}
/deep/ .amap-info-sharp {
  content: "";
  width: 0;
  height: 0;
  position: relative;
  display: block;
  top: -15px;
  left: 50%;
  transform: translate(-50%, 0px) rotate(-36deg);
  color: #fc2e5a;
  border-right: 20px solid transparent;
  border-bottom: 14px solid #fff;
  border-left: 20px solid transparent;
  border-top: 14px solid transparent;
}
/deep/ .amap-info-sharp:after {
  border: none;
}
.amap-box {
  height: 50vh;
}

.search-box {
  position: absolute;
  z-index: 5;
  width: 70%;
  left: 13%;
  top: 10px;
  height: 30px;
}

.search-box .el-input {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0 7px 7px 0;
  outline: none;
  position: relative;
}

.search-box .el-button {
  position: absolute;
  right: 0;
  top: 1px;
  width: 20%;
  background: #38a28a;
  border-radius: 0 7px 7px 0;
  border: none;
  color: #fff;
  outline: none;
}

.tip-box {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 35px;
  padding: 10px 0;
  background-color: #fff;
  opacity: 0.8;
}
.searchBox {
  position: absolute;
  top: 20px;
  left: 40px;
  border: 1px solid #ccc;
  height: 35px;
  margin: 5px auto;
}
</style>
