<template>
  <div>
    <!-- <p class="about">关于我们</p>
    <p class="new">
      致力于成为世界一流的新一代信息技术产业龙头企业，<br />
      经济社会数字化转型的优秀服务商，新型基础设施建设的骨干企业
    </p> -->
    <div class="f_nav">
      <div class="nav">
        <div
          class="jianjie pointer"
          @click="aboutUsClick('jianjie')"
          :class="usClass == 'jianjie' ? 'check' : ''"
        >
          公司简介
        </div>
        <div
          class="jianjie pointer"
          @click="aboutUsClick('licheng')"
          :class="usClass == 'licheng' ? 'check' : ''"
        >
          发展历程
        </div>
        <div
          class="jianjie pointer"
          @click="aboutUsClick('us')"
          :class="usClass == 'us' ? 'check' : ''"
        >
          联系我们
        </div>
      </div>
    </div>
    <div class="middle" v-if="usForm.rmk">
      <div style="margin-bottom: 80px">
        <p class="name" id="jianjie">{{ usForm.name }}</p>
        <div class="ql-snow">
          <div v-html="usForm.rmk" class="ql-editor" />
        </div>
      </div>

      <div class="lcbox" id="licheng" v-if="usForm.devRmk">
        <p class="lianx">发展历程</p>
        <p class="us ql-snow">Development process</p>
        <div class="ql-snow">
          <div v-html="usForm.devRmk" class="ql-editor" />
        </div>
      </div>
      <div id="us">
        <p class="lianx">联系我们</p>
        <p class="us" style="margin-bottom: 50px">Contact us</p>
        <!-- <img class="map" src="../assets/images/map.jpg" /> -->
        <div>
          <Map
            ref="map"
            :selAddr="usForm.addr"
            :lon="usForm.lon"
            :lat="usForm.lat"
          />
        </div>
      </div>
    </div>
    <div class="handshake">
      <img class="bg" src="../assets/images/handshake.png" />
      <div class="four">
        <ul>
          <li class="flo">
            <img
              class="did"
              src="../assets/images/pos.jpg"
              width="40px"
              height="48px"
            />
            <p class="pos">公司地点</p>
            <p class="jiedao">
              {{ usForm.addr }}
            </p>
          </li>
          <li class="flo">
            <img
              class="ph"
              src="../assets/images/pho.jpg"
              width="32px"
              height="50px"
            />
            <p class="pos">联系电话</p>
            <p class="pho">{{ usForm.phone }}</p>
          </li>
          <li class="flo">
            <img
              class="fax"
              src="../assets/images/fax.jpg"
              width="50px"
              height="50px"
            />
            <p class="pos">企业传真</p>
            <p class="pho">{{ usForm.fax }}</p>
          </li>
          <li class="flo">
            <img
              class="ema"
              src="../assets/images/ema.jpg"
              width="58px"
              height="36px"
            />
            <p class="pos">公司邮箱</p>
            <p class="pho">{{ usForm.email }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mgtEntOpenEntInfo } from "../assets/api/index";
import Map from "@/components/Map/amap.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "ent",
  data() {
    return {
      usClass: "jianjie",
      usForm: {},
    };
  },
  components: {
    Map,
  },
  created() {
    if (sessionStorage.getItem("aboutUs") == null) {
      this.getMgtEntOpenEntInfo();
    } else {
      this.usForm = JSON.parse(sessionStorage.getItem("aboutUs"));
    }
  },
  mounted() {},
  methods: {
    aboutUsClick(id) {
      this.usClass = id;
      var anchor = document.getElementById(id);
      // chrome
      document.body.scrollTop = anchor.offsetTop;
      // firefox
      document.documentElement.scrollTop = anchor.offsetTop;
      // safari
      window.pageYOffset = anchor.offsetTop;
    },
    getMgtEntOpenEntInfo() {
      this.$loading();
      mgtEntOpenEntInfo({}).then((res) => {
        this.usForm = res.object;
        sessionStorage.setItem("aboutUs", JSON.stringify(this.usForm));
        this.$loading().close();
      });
    },
  },
};
</script>



<style lang="less" scoped>
.f_nav {
  width: 100%;
  height: 80px;
  background: linear-gradient(180deg, #41aaaa, #5d78ff);
  opacity: 0.92;
  .nav {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .jianjie {
      width: 168px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.check {
  background: rgba(0, 0, 0, 0.27);
}
.middle {
  width: 1183px;
  margin: 0 auto;
}
.about {
  position: absolute;
  top: 278px;
  left: 379px;
  width: 210px;
  height: 49px;
  font-size: 51px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  z-index: 999;
}
.new {
  position: absolute;
  top: 394px;
  left: 375px;
  width: 777px;
  height: 77px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 52px;
  z-index: 999;
}
.name {
  font-size: 24px;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  margin-top: 82px;
}
.main {
  width: 1200px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #575757;
  margin-top: 50px;
  overflow: hidden;
}
.pic {
  width: 1200px;
  height: 451px;
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
}
.hah {
  width: 592px;
  img {
    width: 100%;
  }
}
.lcbox {
  margin-bottom: 80px;
  width: 1200px;
}
.lianx {
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #333333;
  text-align: center;
  line-height: 45px;
}
.us {
  font-size: 8px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #acaeaf;
  text-align: center;
}
.map {
  margin: 0 auto;
  margin-top: 70px;
}
ul {
  display: flex;
  justify-content: space-between;
}
ul li {
  list-style-type: none;
  display: inline;
}
.handshake {
  margin-top: 143px;
  position: relative;
  height: 414px;
  .bg {
    width: 100%;
    height: 100%;
    z-index: -2;
  }
  .four {
    width: 1200px;
    margin: 0 auto;
    position: absolute;
    z-index: 1;
    left: calc(50% - 600px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .flo {
      width: 289px;
      height: 245px;
      background: #ffffff;
      border-radius: 10px;
      .did {
        margin-top: 47px;
        margin-left: 125px;
      }
      .pos {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        text-align: center;
        margin-top: 10px;
      }
      .jiedao {
        width: 244px;
        margin: 0 auto;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 26px;
        margin-top: 18px;
        text-align: center;
      }
      .ph {
        margin-top: 46px;
        margin-left: 129px;
      }
      .pho {
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 26px;
        text-align: center;
        margin-top: 18px;
      }
      .fax {
        margin-top: 46px;
        margin-left: 115px;
      }
      .chuan {
        text-align: center;
        margin-top: 18px;
      }
      .dskj {
        width: 200px;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 26px;
        margin-left: 45px;
      }
      .ema {
        margin-top: 55px;
        margin-left: 116px;
      }
      .qq {
        width: 147px;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 26px;
        margin-left: 66px;
      }
    }
  }
}
</style>
