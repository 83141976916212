/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";

const request = axios.create({
  // baseURL: "http://192.168.1.3:8082/admin", // 基地址
  baseURL: "http://ma.neadt.com/admin", // 基地址
  timeout: 20000, // 登录超时时间
});

export default request;
