<template>
  <div class="index">
    <div class="addbox">
      <div class="addCon">
        <div class="acLeft">
          <div class="bg_title">东数工业互联网平台</div>
          <div class="bg_content" style="color: rgb(96, 96, 96)">
            基于国家工业互联网标识体系，全面链通产业链，打破数据孤岛，助力企业数字化转型发展。
          </div>
          <div
            class="sqadd"
            @click="
              toHerf('http://180.76.146.129:8000/snms/ui/login')
            "
          >
            企业标识注册入口
          </div>
        </div>
        <div class="acRight">
          <img src="../assets/images/bg2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="box1">
      <div class="boxText1">工业互联网标识应用</div>
      <div class="boxText2">
        公司主营业务包括“宽带城市”、“互联网+”、大数据、云计算、人工智能、数字经济、电子政务、新型数字城市、数字乡村等内容。
      </div>
      <div class="box1List">
        <div class="listClass" v-for="(item, index) in boxList01" :key="index">
          <img :src="item.url" alt="" />
          <div class="listTitle">{{ item.title }}</div>
          <div class="listMess">{{ item.prtk }}</div>
        </div>
      </div>
    </div>
    <div style="background: #f0f6ff">
      <div class="box2">
        <div class="box2T">解决方案</div>
        <div class="blueTiao"></div>
        <div class="blueText">引领信息科技浪潮，推动社会文明进步</div>
        <div class="solnBox">
          <el-carousel :interval="1000000" type="card" height="400px">
            <el-carousel-item v-for="item in solnList" :key="item.id">
              <div
                class="banBgc"
                @click="$router.push('/solnPageDetail?id=' + item.id)"
              >
                <div class="bb1">{{ item.title }}</div>
                <div class="bb2">
                  {{ item.prtk }}
                </div>
                <img :src="item.url" alt="" class="" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="box2T">新闻资讯</div>
      <div class="blueTiao"></div>
      <div>
        <el-tabs v-model="newsName" @tab-click="newTypeClick">
          <el-tab-pane label="新闻动态" name="first"> </el-tab-pane>
          <el-tab-pane label="政策法规" name="second"> </el-tab-pane>
          <el-tab-pane label="行业观察" name="third"> </el-tab-pane>
        </el-tabs>
        <div class="news pointer">
          <div
            class="newsLeft"
            @click="$router.push('/articleDetail?id=' + mgtNewList[0].id)"
            v-if="mgtNewList[0]"
          >
            <div class="newsImg">
              <img :src="mgtNewList[0].url" />
              <!-- <div class="l-btn"></div> -->
            </div>
            <div class="title">
              {{
                mgtNewList[0].title + " " + "[" + mgtNewList[0].relDate + "]"
              }}
            </div>
            <div class="content">
              {{ mgtNewList[0].prtk }}
            </div>
            <div class="moreBtn pointer" @click="$router.push('/newsList')">
              查看更多 <i class="el-icon-right"></i>
            </div>
            <div style="height: 20px"></div>
          </div>
          <div
            class="newsRight"
            @click="$router.push('/articleDetail?id=' + mgtNewList[1].id)"
            v-if="mgtNewList[1]"
          >
            <div class="top">
              <div class="top-writing">
                <div class="title">
                  {{ mgtNewList[1].title }}
                </div>
                <div class="content">
                  {{ mgtNewList[1].prtk }}
                </div>
              </div>
              <div class="time">
                <div class="day">{{ mgtNewList[1].relDate.slice(-2) }}</div>
                <div class="date">{{ mgtNewList[1].relDate.slice(0, 7) }}</div>
              </div>
            </div>
            <div
              v-for="(item, index) in mgtNewList"
              v-show="index >= 2 && index < 6"
              :key="index"
              class="list-item pointer hoverBlue"
              @click="$router.push('/articleDetail?id=' + item.id)"
            >
              <div>{{ item.title }}</div>
              <div style="min-width: 75px; text-align: right">
                {{ item.relDate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cooperate">
      <div style="margin-bottom: 36px">
        东数科技<span style="color: #0092d1">合作伙伴</span>
      </div>
      <div style="font-size: 16px; color: #666666">
        长期合作且可信懒的核心供应商，均已达成长期的战略合作目标
      </div>
    </div>
    <div class="partner">
      <div
        v-for="item in mgtPrsList"
        :key="item.id"
        @click="toHerf(item.href)"
        class="partner-item pointer"
      >
        <div class="partner-item-img">
          <img :src="item.url" />
        </div>
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
import {
  mgtApidList,
  mgtSolnList,
  mgtPrsList,
  mgtNewsList,
} from "../assets/api/index";

export default {
  name: "BusinessMid",
  data() {
    return {
      boxList01: [],
      solnList: [],
      mgtPrsList: [],
      mgtNewList: [],
      page: {
        size: 6,
      },
      newsName: "first",
    };
  },
  mounted() {
    let data = {
      page: this.page,
      type: "0",
    };
    this.getMgtApidList();
    this.getMgtSolnList();
    this.getMgtNewsList(data);
    this.getMgtPrsList();
  },
  methods: {
    toHerf(href) {
      window.open(href, "_blank");
    },
    // 新闻类型选择
    newTypeClick(tab, event) {
      let data = {
        page: this.page,
        type: tab.index,
      };
      this.getMgtNewsList(data);
    },
    // 工业互联网标识应用
    getMgtApidList() {
      mgtApidList({}).then((res) => {
        this.boxList01 = res.object;
      });
    },
    // 解决方案
    getMgtSolnList() {
      mgtSolnList({}).then((res) => {
        this.solnList = res.object;
      });
    },
    // 新闻中心
    getMgtNewsList(data) {
      mgtNewsList(data).then((res) => {
        this.mgtNewList = res.object;
      });
    },
    // 东数科技合作伙伴
    getMgtPrsList() {
      mgtPrsList({}).then((res) => {
        this.mgtPrsList = res.object;
      });
    },
  },
};
</script>

<style scoped lang="less">
.addbox {
  width: 100%;
  height: 599px;
  background: #f7f7f7;
}
.addCon {
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 1200px;
  margin: 0 auto;
}
.bg_title {
  font-size: 40px;
  font-family: Source Han Sans CN;
  font-weight: 400;
}

.bg_content {
  width: 482px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 44px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.sqadd {
  width: 207px;
  height: 45px;
  background: linear-gradient(-30deg, #0194cb, #005afc);
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
}
.acRight {
  img {
    border: 0;
    vertical-align: middle;
  }
}

/deep/.el-tabs__nav {
  margin: 0 auto;
  width: 248px;
  float: none;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: #fff;
}
/deep/.el-tabs__content {
  margin-top: 48px;
}
.index {
  .box1 {
    width: 1200px;
    margin: 0 auto;
    padding: 70px 0;
    box-sizing: border-box;
    .boxText1 {
      font-size: 21px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #010304;
      text-align: center;
      line-height: 1;
      margin-bottom: 35px;
    }
    .boxText2 {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #636566;
      text-align: center;
      line-height: 1;
      margin-bottom: 70px;
    }
    .box1List {
      display: flex;
      flex-wrap: wrap;
      .listClass {
        width: 300px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 50px 68px;
        box-sizing: border-box;

        img {
          width: 40px;
          height: 40px;
          margin-bottom: 12px;
        }
        .listTitle {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 1;
          text-align: center;
          margin-bottom: 10px;
        }
        .listMess {
          font-size: 8px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #636566;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
  .box2 {
    width: 1200px;
    margin: 0 auto;
    padding: 60px 0 40px;

    .box2T {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 1;
      text-align: center;
      margin-bottom: 22px;
    }
    .blueTiao {
      width: 80px;
      height: 4px;
      background: linear-gradient(180deg, #0095cf, #0060fc);
      border-radius: 2px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .blueText {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #636566;
      line-height: 1;
      text-align: center;
      margin-bottom: 50px;
    }
    .solnBox {
      .banBgc {
        margin: 0 auto;
        height: 300px;
        background: #ffffff;
        box-shadow: 0px 7px 32px 0px rgba(0, 133, 220, 0.21);
        opacity: 0.75;
        border-radius: 14px;
        padding: 32px 28px 10px 28px;
        overflow: hidden;
        margin-top: 20px;
        position: relative;
        .bb1 {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 22px;
          margin-bottom: 18px;
        }
        .bb2 {
          font-size: 10px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        img {
          width: auto;
          height: 100px;
          position: absolute;
          bottom: 10px;
          right: 20px;
        }
      }
    }
  }
}
.el-carousel__item {
  padding: 0 124px 0 114px;
  box-sizing: border-box;
}

.news {
  display: flex;
  .newsLeft {
    width: 590px;
    margin-right: 34px;
    .newsImg {
      width: 590px;
      height: 197px;
      position: relative;

      .l-btn {
        position: absolute;
        width: 47px;
        height: 47px;
        background: #000000;
        opacity: 0.6;
        bottom: 0;
        right: 48px;
        z-index: 1;
      }

      .r-btn {
        position: absolute;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-top: 34px;
      margin-bottom: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .content {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #9d9d9d;
    }
    .moreBtn {
      width: 115px;
      height: 27px;
      line-height: 27px;
      text-align: center;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #8e8c8c;
      box-shadow: 0px 1px 16px 0px rgba(69, 194, 252, 0.21);
      margin: 14px 22px;
      margin-left: 6px;
    }
  }
  .newsRight {
    flex: 1;
    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 36px;
      .top-writing {
        width: 462px;
        .title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          margin-bottom: 34px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .content {
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #9d9d9d;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .time {
        margin-top: -14px;
        text-align: center;
        .day {
          font-size: 50px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
        .date {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
      }
    }
    .list-item {
      padding: 18px 2px;
      border-bottom: 1px solid #d2d2d2;
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
  }
}

.cooperate {
  background: #f1f1f1;
  text-align: center;
  font-size: 35px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  padding-top: 84px;
  padding-bottom: 60px;
}

.partner {
  display: flex;
  justify-content: center;
  padding: 80px 0;
  .partner-item {
    text-align: center;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    .partner-item-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 114px;
      border: 1px solid #a0a0a0;
      margin-right: -1px;
      margin-bottom: 10px;
      img {
        width: auto;
        height: 40px;
      }
    }
  }
}
/deep/.el-carousel__item .el-carousel__mask {
  background-color: rgb(240, 246, 255) !important;
}
</style>
