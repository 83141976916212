import request from "./request";
// 二级节点标识解析的作用
export function mgtTwoAnalPage(data) {
  return request({
    url: "/mgtTwoAnal/page",
    method: "post",
    data,
  }).then(res => {
    return res.data
  });
}
// 解决方案 详情
export function mgtSolnForm(data) {
  return request({
    url: "/mgtSoln/form",
    method: "post",
    data,
  }).then(res => {
    return res.data
  });
}
