<template>
  <div>
    <div class="page">
      <div class="pageTop">
        <div class="pageTopLeft">
          <div class="pageTopLeft1">什么是二级节点标识解析</div>
          <div class="pageTopLeft2">在工业互联网体系中，网络是基础，而标识是网络的基础， 是网络的“身份证”。行业专家曾指出:工业互联网标识解 析体系，是实现工业全要素、
            各环节信息互通的关键枢纽。通过给每一个对象(物)赋予 标识，并借助工业互联网标识解析系统，实现跨地域、跨行业、跨企业的信息查询和共享。
          </div>
        </div>
        <div class="pageTopRight">
          <div class="img1">
            <img src="../assets/images/top1.png" alt="" />
          </div>
          <div class="img2">
            <img src="../assets/images/top2.png" alt="" />
          </div>
        </div>
      </div>
      <div class="pageMiddle">
        <div class="pageMiddle1">二级节点标识解析的作用</div>
        <div class="pageMiddle2">工业标识解析体系是推动工业互联网建设的基础，是制造业企业数字化的基本条件，是连接企业间各项业务的 前提，是企业进行大数据采集和经营决策的依据。</div>
      </div>
    </div>
    
    <div class="pageBottom" v-if="list.length">
      <div class="pageBottom8"></div>
      <div class="pageBottom1">
        <img src="../assets/images/twoAnal.png" alt="" style="width: 100%;vertical-align: text-bottom;" />
        <div class="choose">
          <div style="height: 30px;"></div>
          <div class="list1" :class="{ active: index == i }" @click="index = i" v-for="(item, i) in list" :key="item.id">
            <div class="listCont">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="pageBottom2">
        <div class="coutent" v-if="index == 0">
          <div class="countentSize">{{ list.length && list[0].prtk }}</div>
        </div>
        <img :src="list[0].url" alt="" style="width: 100%;vertical-align: text-bottom;" />
      </div>
      <div class="pageBottom3">
        <div class="coutent" v-if="index == 1">
          <div class="countentSize">{{ list[1].prtk }}</div>
        </div>
        <img :src="list[1].url" alt="" style="width: 100%;vertical-align: text-bottom;" />
      </div>
      <div class="pageBottom4">
        <div class="coutent" v-if="index == 2">
          <div class="countentSize">{{ list[2].prtk }}</div>
        </div>
        <img :src="list[2].url" alt="" style="width: 100%;vertical-align: text-bottom;" />
      </div>
      <div class="pageBottom5">
        <div class="coutent" v-if="index == 3">
          <div class="countentSize">{{ list[3].prtk }}</div>
        </div>
        <img :src="list[3].url" alt="" style="width: 100%;vertical-align: text-bottom;" />
      </div>
      <div class="pageBottom6">
        <div class="coutent" v-if="index == 4">
          <div class="countentSize">{{ list[4].prtk }}</div>
        </div>
        <img :src="list[4].url" alt="" style="width: 100%;vertical-align: text-bottom;" />
      </div>
      <div class="pageBottom7"></div>
      <div class="pageBottom9"></div>
    </div>
  </div>
</template>
<script>
import { mgtTwoAnalPage } from "../assets/api/api";

export default {
  name: "twoAnal",
  data() {
    return {
      list: [],
      index: 0
    };
  },
  mounted() {
    this.getMgtTwoAnalPage()
  },
  methods: {
    getMgtTwoAnalPage() {
      mgtTwoAnalPage({ page: { size: 5 } }).then((res) => {
        this.list = res.object.records;
      });
    }
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 1200px;
  margin: 0 auto;
  min-height: 200px;
}

.pageTopLeft {
  width: 475px;
  min-height: 100px;
  margin-top: 132px;
  margin-right: 35px;
  display: inline-block;
  .pageTopLeft1 {
    font-size: 36px;
    margin-bottom: 40px;
  }
  .pageTopLeft2 {
    font-size: 18px;
    line-height: 35px;
    color: #0E0707;
  }
}
.pageTopRight {
  display: inline-block;
  margin-top: 117px;
  .img1 {
    width: 225px;
    height: 234px;
    display: inline-block;
  }
  .img2 {
    width: 465px;
    height: 234px;
    display: inline-block;
  }
}
.pageMiddle {
  margin-top: 191px;
  text-align: center;
  .pageMiddle1 {
    font-size: 36px;
    margin-bottom: 41px;
  }
  .pageMiddle2 {
    margin: 0 auto;
    width: 783px;
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
    color: #7F7C7C;
  }
}

.pageBottom {
  width: 1200px;
  margin: 95px auto 195px;
  position: relative;
  .pageBottom1 {
    position: relative;
    width: 320px;
    height: 250px;
    display: inline-block;
    margin-left: 210px;
    .choose {
      position: absolute;
      left: 0px;
      top: 0;
      width: 46%;
      height: 100%;
      background: rgba(0, 106, 243, .6);
      .list1 {
        width: 100%;
        height: 16%;
        .listCont {
          font-size: 15px;
          color: #ffffff;
          text-align: center;
          padding-top: 10px;
          cursor: default;
        }
      }
      .active {
        background: rgba(0, 0, 0, .27)
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .pageBottom2,
  .pageBottom3,
  .pageBottom4,
  .pageBottom5,
  .pageBottom6 {
    position: relative;
    width: 320px;
    height: 250px;
    display: inline-block;
    .coutent {
      position: absolute;
      top: 10px;
      left: 12px;
      width: 93%;
      height: 90%;
      background: linear-gradient(0deg, rgba(215, 215, 215, .6), rgba(0, 150, 206, .6), rgba(0, 110, 240, .6));
      .countentSize {
        width: 60%;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 20px;
        margin: 16% auto 0;
        letter-spacing: 2px;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .pageBottom7 {
    position: absolute;
    right: 140px;
    bottom: 0px;
    width: 100px;
    height: 100px;
    display: inline-block;
    background: linear-gradient(0deg, #D7D7D7, #0096CE, #006EF0);
  }
  .pageBottom8 {
    position: absolute;
    left: -30px;
    bottom: 248px;
    width: 100px;
    height: 100px;
    margin-left: 140px;
    display: inline-block;
    background-color: #eaf1f9;
  }
  .pageBottom9 {
    position: absolute;
    right: 180px;
    bottom: 190px;
    width: 60px;
    height: 60px;
    display: inline-block;
    background-color: #eaf1f9;
  }
}
</style>
