<template>
  <div class="container">
    <div class="center"><span class="pointer" @click="$router.push('/solnPage')">官方解决方案</span>>详情内容</div>
    <div class="center-two">
      <div class="text">{{ form.title }}</div>
      <div class="xian"></div>
      <!-- <div class="img">
        <img class="tu" :src="form.url" alt="" />
      </div> -->
      <div class="text2" v-html="form.rmk">
      </div>
    </div>
  </div>
</template>
<script>
import { mgtSolnForm } from "../assets/api/api";

export default {
  name: "solnPageDetail",
  data() {
    return {
      form: {}
    };
  },
  mounted() {
    if(!this.$route.query.id) {
      return
    }
    mgtSolnForm({ id: this.$route.query.id }).then((res) => {
      this.form = res.object;
    });
  },
  methods: {},
};
</script>



<style lang="less" scoped>
.container {
  
  background-color: #f7f7f7;
}
.center {
  margin: 0 auto;
  width: 1200px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #878685;
  padding-top: 34px;
  padding-bottom: 30px;
}
.center-two {
  margin: 0 auto;
  width: 1200px;
  background: #ffffff;
  margin: 0 auto;
  padding: 53px 50px;
  padding-bottom: 240px;
}
.text {
  font-size: 34px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.xian {
  margin-top: 44px;
  height: 1px;
  background: #d2d2d2;
}
.text1 {
  font-size: 14px;
  text-indent: 2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #636566;
  line-height: 30px;
  margin-top: 55px;
}
.img {
  width: 678px;
  margin: 0 auto;
  background: #ffffff;
  margin-top: 38px;
  margin-bottom: 74px;
}
.img .tu {
  width: 100%;
}
.text2 {
  font-size: 14px;
  text-indent: 2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #636566;
  line-height: 30px;
}
</style>
