<template>
  <div class="background">
    <div class="page">
      <div class="title">新闻资讯</div>
      <div class="underline"></div>
      <el-tabs @tab-click="tabClick">
        <el-tab-pane label="新闻动态" name="0"></el-tab-pane>
        <el-tab-pane label="政策法规" name="1"></el-tab-pane>
        <el-tab-pane label="行业观察" name="2"></el-tab-pane>
      </el-tabs>
      <div class="detail">
        <div class="detailLeft">
          <img src="../assets/images/top3.png" alt="" style="width: 100%" />
        </div>
        <div class="detailMiddle">
          <div
            class="detailMiddle1"
            v-for="(item, index) in list1"
            :key="index"
            @click="$router.push('/articleDetail?id=' + item.id)"
          >
            <span class="detailMiddleTitle">{{ item.title }}</span>
            <span class="detailMiddleContent">{{ item.prtk }}</span>
            <div class="detailMiddleDate">
              <div class="date1">{{ item.relDate.slice(-2) }}</div>
              <div class="date2">{{ item.relDate.slice(0, 7) }}</div>
              <div class="date3">→</div>
            </div>
          </div>
        </div>
        <div class="detailRight">
          <div
            class="detailRight1"
            v-for="(item, index) in list2"
            :key="index"
            @click="$router.push('/articleDetail?id=' + item.id)"
          >
            <div class="rightDate">
              <span class="rightDate1">{{ item.relDate.slice(-2) }}</span>
              <span class="rightDate2">{{ item.relDate.slice(0, 7) }}</span>
            </div>
            <div class="solidDate"></div>
            <div class="rightTitle">
              <div class="rightTitle1">{{ item.title }}</div>
              <div class="rightTitle2">→</div>
            </div>
          </div>
          <!-- <div class="detailRight1">
            <div class="rightDate">
              <span class="rightDate1">29</span>
              <span class="rightDate2">2023-03</span>
            </div>
            <div class="solidDate"></div>
            <div class="rightTitle">
              <div class="rightTitle1">{{ list[0].prtk }}</div>
              <div class="rightTitle2">→</div>
            </div>
          </div>
          <div class="detailRight1">
            <div class="rightDate">
              <span class="rightDate1">29</span>
              <span class="rightDate2">2023-03</span>
            </div>
            <div class="solidDate"></div>
            <div class="rightTitle">
              <div class="rightTitle1">{{ list[0].prtk }}</div>
              <div class="rightTitle2">→</div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="form" @click="$router.push('/newsList')">查看更多</div>
    </div>
  </div>
</template>
<script>
import { mgtNewsList } from "../assets/api/news";

export default {
  name: "news",
  data() {
    return {
      list: [],
      list1: [],
      list2: [],
      page: { size: 5 },
    };
  },
  mounted() {
    let data = {
      page: this.page,
      type: "0",
    };
    this.getMgtNewsList(data);
  },
  methods: {
    getMgtNewsList(data) {
      this.list1 = [];
      this.list2 = [];
      mgtNewsList(data).then((res) => {
        for (var i = 0; i < res.object.length; i++) {
          if (i < 2) {
            this.list1.push(res.object[i]);
          } else if (i >= 2 && i < 5) {
            this.list2.push(res.object[i]);
          }
        }
      });
    },
    tabClick(tab, event) {
      let data = {
        page: this.page,
        type: tab.index,
      };
      this.getMgtNewsList(data);
    },
  },
};
</script>



<style lang="less" scoped>
/deep/.el-tabs__nav {
  margin: 0 auto;
  width: 248px;
  float: none;
}
/deep/.el-tabs__item {
  font-size: 18px !important;
}
/deep/.el-tabs__header {
  margin: 0;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: rgba(0, 0, 0, 0);
}
.background {
  background-image: url(../assets/images/bj.png);
  padding: 132px 0;
}
.page {
  width: 1200px;
  margin: auto;
  box-sizing: border-box;
  .title {
    font-size: 48px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    text-align: center;
    margin: 0 0 34px;
  }
  .underline {
    width: 114px;
    height: 6px;
    background: linear-gradient(180deg, #0095cf, #0060fc);
    border-radius: 3px;
    margin: 0 auto 47px;
  }
  .minTitle {
    width: 302px;
    height: 19px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 30px;
    margin: 0 auto 47px;
    .dynamic {
      margin-right: 40px;
      color: #0080e1;
      border-bottom: 2px solid #0080e1;
      display: inline-block;
    }
    .statute {
      margin-right: 40px;
      color: #999999;
      display: inline-block;
    }
    .observe {
      color: #999999;
      display: inline-block;
    }
  }
  .detail {
    height: 464px;
    display: flex;
    margin-top: 47px;
    .detailLeft {
      display: inline-block;
      // background-color: #0095cf;
      width: 32.5%;
      height: 100%;
      box-shadow: 0px 10px 6px 0px rgba(184, 185, 185, 0.21);
      border-radius: 10px;
    }
    .detailMiddle {
      display: inline-block;
      width: 32.7%;
      height: 100%;
      margin: 0 15px;
      .detailMiddle1 {
        width: 100%;
        height: 224px;
        background: #ffffff;
        box-shadow: 0px 10px 6px 0px rgba(184, 185, 185, 0.21);
        border-radius: 10px;
        margin-bottom: 15px;
        cursor: pointer;
        .detailMiddleTitle {
          display: inline-block;
          width: 322px;
          margin: 24px 0 18px 29px;
          font-size: 22px;
          font-weight: 400;
          color: #231815;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .detailMiddleContent {
          width: 345px;
          font-size: 17px;
          font-weight: 300;
          color: #231815;
          line-height: 25px;
          margin: 0 0 22px 27px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .detailMiddleDate {
          position: relative;
          .date1 {
            font-size: 46px;
            color: #bdbcbc;
            line-height: 23px;
            margin: 0 0 10px 29px;
          }
          .date2 {
            font-size: 16px;
            color: #bdbcbc;
            line-height: 25px;
            margin: 0 0 0 29px;
          }
          .date3 {
            position: absolute;
            right: 42px;
            bottom: 20px;
            font-size: 33px;
            color: #bdbcbc;
          }
        }
      }
      .detailMiddle1 :last-child {
        margin: 0;
      }
    }
    .detailRight {
      display: inline-block;
      width: 32%;
      height: 100%;
      .detailRight1 {
        position: relative;
        width: 100%;
        height: 144px;
        background: #ffffff;
        box-shadow: 0px 10px 6px 0px rgba(184, 185, 185, 0.21);
        border-radius: 10px;
        margin-bottom: 15px;
        cursor: pointer;
        .rightDate {
          display: inline-block;
          .rightDate1 {
            display: inline-block;
            font-size: 48px;
            color: #bdbcbc;
            line-height: 30px;
            margin: 48px 0 13px 44px;
          }
          .rightDate2 {
            display: block;
            font-size: 16px;
            color: #bdbcbc;
            line-height: 30px;
            margin: 0 0 0 39px;
          }
        }
        .solidDate {
          position: absolute;
          top: 28px;
          left: 135px;
          width: 1px;
          height: 88px;
          background: #d2d2d2;
        }
        .rightTitle {
          display: inline-block;
          position: absolute;
          right: 30px;
          top: 40px;
          .rightTitle1 {
            width: 200px;
            font-size: 22px;
            font-weight: 400;
            color: #231815;
            margin-bottom: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .rightTitle2 {
            font-size: 33px;
            color: #bdbcbc;
          }
        }
      }
      .detailRight1 :last-child {
        margin: 0;
      }
    }
  }
  .form {
    width: 156px;
    height: 44px;
    margin: 73px auto 0;
    font-size: 16px;
    text-align: center;
    line-height: 44px;
    color: #8e8c8c;
    background: #ffffff;
    box-shadow: 0px 1px 16px 0px rgba(69, 194, 252, 0.21);
    cursor: pointer;
  }
}
</style>
