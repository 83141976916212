import request from "./request";
export function mgtSolnList(data) {
  return request({
    url: "/mgtSoln/list",
    method: "post",
    data,
  }).then(res => {
    return res.data
  });
}
