<template>
  <div class="container">
    <div class="center" id="center">
      <!-- <div class="tittle">{{ newsForm.news.title }}</div> -->
      <!-- <div class="centerimg">
        <img :src="newsForm.news.url" alt="" />
      </div> -->
      <div v-html="rmk" class="neirong"></div>
    </div>
  </div>
</template>
<script>
import { newsForm } from "../assets/api/index";
import { mgtProductFromPlateForm } from "../assets/api/product";
export default {
  name: "solnPage",
  data() {
    return {
      plateFrom: {},
      rmk: "",
      id: this.$route.query.id,
    };
  },
  watch: {
    id(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getplateFrom({ id: this.id });
        var anchor = document.getElementById("center");
        // chrome
        document.body.scrollTop = anchor.offsetTop;
        // firefox
        document.documentElement.scrollTop = anchor.offsetTop;
        // safari
        window.pageYOffset = anchor.offsetTop;
      }
    },
  },
  mounted() {
    this.getplateFrom({ id: this.id });
  },
  methods: {
    getplateFrom(data) {
      mgtProductFromPlateForm(data).then((res) => {
        this.rmk = this.fixImgInRichText(res.object.rmk);
      });
    },
    fixImgInRichText(htmlSnip) {
      let regex1 = new RegExp(
        "(i?)(<img)(?!(.*?style=['\"](.*)['\"])[^>]+>)",
        "gmi"
      );
      // 给不含 style="" 或 style='' 的 img 标签加上 style=""
      htmlSnip = htmlSnip.replace(regex1, '$2 style=""$3');
      // 正则匹配含有 style 的 img 标签
      let regex2 = new RegExp("(i?)(<img.*?style=['\"])([^>]+>)", "gmi");
      htmlSnip = htmlSnip.replace(
        regex2,
        "$2height: auto; width: 100%;margin: 0 auto;display: block;$3"
      );
      return htmlSnip;
    },
  },
};
</script>



<style lang="less" scoped>
.container {
  background: #f8f8f8;
  width: 100%;
  //   padding-bottom: 170px;
}
.center {
  background-color: #ffffff;
  width: 1200px;
  margin: 0 auto;
  //   margin-top: -88px;
  z-index: 200;
  position: relative;
  padding: 42px;
  padding-bottom: 60px;
  overflow: hidden;
  min-height: 720px;

  .neirong {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #797979;
    text-indent: 2rem;
    p {
      img {
        width: 100%;
      }
    }
    // margin-bottom: 100px;
  }
}
</style>
