<template>
  <div class="page">
    <div
      class="list-item"
      v-for="(item, index) in mgtNewList.records"
      :key="index"
      @click="$router.push('/articleDetail?id=' + item.id)"
    >
      <div class="item-img">
        <img :src="item.url" alt="" />
      </div>
      <div class="item-news">
        <div class="news-title">
          {{ item.title }}
        </div>
        <div class="news-date">
          <span>发布时间：{{ item.relDate.replace(/-/g, "/") }}</span
          ><span>浏览次数：{{ item.viewsNum }}</span>
        </div>
        <div
          class="news-content"
        >
          {{ item.prtk }}
        </div>
      </div>
    </div>
    <div style="margin-top: 67px; text-align: center">
      <el-pagination
        background
        layout="prev, pager, next, total, jumper"
        :total="mgtNewList.total"
        :page-size="10"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <div style="height: 200px"></div>
  </div>
</template>
<script>
// import { home, member } from "../assets/api/api";
import { mgtNewsPage } from "../assets/api/index";

export default {
  name: "newsList",
  data() {
    return {
      mgtNewList: [],
      page: {
        size: 10,
        current: 1,
      },
    };
  },
  mounted() {
    let data = {
      page: this.page,
    };
    this.getMgtNewsList(data);
  },
  methods: {
    handleCurrentChange(val) {
      this.page.current = val;
      let data = {
        page: this.page,
      };
      this.getMgtNewsList(data);
    },
    // 新闻中心
    getMgtNewsList(data) {
      mgtNewsPage(data).then((res) => {
        this.mgtNewList = res.object;
      });
    },
  },
};
</script>



<style lang="less" scoped>
.page {
  width: 1200px;
  margin: 0 auto;
  .list-item {
    display: flex;
    border-bottom: 1px solid #c8c8c8;
    padding: 47px 3px;
    .item-img {
      width: 235px;
      height: 166px;
      margin-right: 25px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-news {
      flex: 1;
      .news-title {
        width: 515px;
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: #221714;
        margin-bottom: 30px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .news-date {
        width: 303px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #a09d9a;
        margin-bottom: 34px;
        display: flex;
        justify-content: space-between;
      }
      .news-content {
        font-size: 16px;
        font-family: MicrosoftYaHeiLight;
        color: #221714;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        cursor: pointer;
      }
    }
  }
}
</style>
