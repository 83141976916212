//创建整个应用的路由器
import VueRouter from "vue-router";
//创建一个路由器

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch(err => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }
  return originalReplace.call(this, location).catch(err => err);
};

// import Constitution from "@/pages/constitution";
import Index from "../pages/index";
import twoAnal from "@/pages/twoAnal";
import solnPage from "@/pages/solnPage.vue";
import solnPageDetail from "@/pages/solnPageDetail.vue";
import product from "@/pages/product.vue";
import productList from "@/pages/productList.vue";
import news from "@/pages/news.vue";
import ent from "@/pages/ent.vue";
import articleDetail from "@/pages/articleDetail.vue";
import newsList from "@/pages/newsList.vue";
import searchPage from "@/pages/searchPage.vue"

export default new VueRouter({
  routes: [
    //首页
    {
      path: "/index",
      name: "BusinessIndex",
      component: Index,
    },
    //默认页
    {
      path: "/",
      redirect: "/index",
    },
    //默认页
    {
      path: "/Index",
      redirect: "/index",
    },
    //二级节点
    {
      path: "/twoAnal",
      name: "twoAnal",
      component: twoAnal,
    },
    //解决方案
    {
      path: "/solnPage",
      name: "solnPage",
      component: solnPage,
    },
    //解决方案详情
    {
      path: "/solnPageDetail",
      name: "solnPageDetail",
      component: solnPageDetail,
    },

    //产品中心
    {
      path: "/product",
      name: "product",
      component: product,
    },
    //产品中心详情
    {
      path: "/productList",
      name: "productList",
      component: productList,
    },
    //新闻资讯
    {
      path: "/news",
      name: "news",
      component: news,
    },
    //新闻资讯
    {
      path: "/newsList",
      name: "newsList",
      component: newsList,
    },
    //文章详情
    {
      path: "/articleDetail",
      name: "articleDetail",
      component: articleDetail,
    },
    //关于我们
    {
      path: "/ent",
      name: "ent",
      component: ent,
    },
    //查询结果页
    {
      path: "/searchPage",
      name: "searchPage",
      component: searchPage,
    },
  ],
});