<template>
  <div class="container">
    <div class="center" id="center">
      <div class="top-box">
        <div>
          <span
            ><i style="margin-right: 6px" class="el-icon-time"></i>发布于{{
              newsForm.news.relDate.replace(/-/g, ".")
            }}</span
          >
          <span class="shu"></span>
          <span>浏览量:{{ newsForm.news.viewsNum }}</span>
        </div>
        <div @click="$router.push('/newsList')">
          <div class="backBtn pointer">
            返回新闻列表
            <i class="el-icon-right"></i>
          </div>
        </div>
      </div>
      <div class="tittle">{{ newsForm.news.title }}</div>
      <!-- <div class="centerimg">
        <img :src="newsForm.news.url" alt="" />
      </div> -->
      <div v-html="newsForm.news.rmk" class="neirong"></div>
      <div
        class="other pointer"
        v-if="newsForm.lastNews != null"
        @click="id = newsForm.lastNews.id"
      >
        <i class="el-icon-arrow-left"></i>上一篇：{{ newsForm.lastNews.title }}
      </div>
      <div
        class="other pointer"
        v-if="newsForm.nextNews != null"
        @click="id = newsForm.nextNews.id"
      >
        <i class="el-icon-arrow-right"></i>下一篇：{{ newsForm.nextNews.title }}
      </div>
    </div>
    <div class="bottom-box" v-if="newsForm.hotNews.length > 0">
      <div class="dtext">
        <div class="zi"></div>
        <div class="zi1">热门新闻</div>
      </div>
      <div class="list">
        <div
          class="list-item pointer"
          v-for="(item, index) in newsForm.hotNews"
          @click="id = item.id"
          :key="index"
        >
          <div class="list-item-img">
            <img :src="item.url" alt="" />
          </div>
          <div class="title">
            {{ item.title }}
          </div>
          <div class="describe">
            {{ item.prtk }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newsForm } from "../assets/api/index";

export default {
  name: "solnPage",
  data() {
    return {
      newsForm: {},
      id: this.$route.query.id,
    };
  },
  watch: {
    id(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getNewsForm({ id: this.id });
        var anchor = document.getElementById("center");
        // chrome
        document.body.scrollTop = anchor.offsetTop;
        // firefox
        document.documentElement.scrollTop = anchor.offsetTop;
        // safari
        window.pageYOffset = anchor.offsetTop;
      }
    },
  },
  mounted() {
    this.getNewsForm({ id: this.id });
  },
  methods: {
    getNewsForm(data) {
      newsForm(data).then((res) => {
        this.newsForm = res.body;
      });
    },
  },
};
</script>



<style lang="less" scoped>
.container {
  background: #f8f8f8;
  width: 100%;
  padding-bottom: 170px;
}
.center {
  background-color: #ffffff;
  width: 1200px;
  margin: 0 auto;
  margin-top: -88px;
  z-index: 200;
  position: relative;
  padding: 0 42px;
  padding-bottom: 60px;
  overflow: hidden;
  .top-box {
    width: 100%;
    height: 87px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
    padding-top: 33px;
    padding-bottom: 20px;
    color: #838282;
    .shu {
      display: inline-block;
      width: 1px;
      height: 12px;
      background: #666666;
      margin: 0 10px;
    }
    .backBtn {
      height: 34px;
      line-height: 34px;
      background: linear-gradient(135deg, #0194ca, #0158fa);
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
      border-radius: 17px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      padding: 0 15px;
      .el-icon-right {
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 22px;
        display: inline-block;
        background-color: #fff;
        border-radius: 50%;
        color: #4f9eed;
      }
    }
  }
  .tittle {
    margin-bottom: 30px;
    margin-top: 54px;
    text-align: center;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .centerimg {
    width: 949px;
    margin: 0 auto;
    margin-bottom: 35px;
    img {
      width: 100%;
    }
  }
  .neirong {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #797979;
    text-indent: 2rem;
    margin-bottom: 100px;
  }
  .other {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-top: 30px;
    color: #666;
    vertical-align: middle;
    .el-icon-arrow-left,
    .el-icon-arrow-right {
      width: 22px;
      height: 22px;
      text-align: center;
      line-height: 22px;
      display: inline-block;
      border: 1px solid #666;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .other:hover {
    color: #333;
    .el-icon-arrow-left,
    .el-icon-arrow-right {
      border-color: #333;
    }
  }
}
.bottom-box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 35px;
  background: #ffffff;
  padding: 0 100px;
  padding-bottom: 100px;
  padding-top: 38px;
  .dtext {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    .zi {
      width: 4px;
      height: 17px;
      background: linear-gradient(135deg, #0194ca, #0158fa);
      margin-right: 10px;
    }
    .zi1 {
      font-size: 17px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    .list-item {
      width: 300px;
      padding-bottom: 38px;
      border-bottom: 1px solid #e6e6e6;
      .list-item-img {
        width: 100%;
        img {
          width: 100%;
          height: 205px;
        }
      }
      .title {
        width: 260px;
        font-size: 17px;
        font-family: PingFang;
        font-weight: 400;
        color: #666666;
        margin: 20px 0;
        overflow: hidden; //超出隐藏
        white-space: nowrap; //不换行，同一行展示
        text-overflow: ellipsis; //设置超出部分以省略号展示
      }
      .describe {
        font-size: 13px;
        font-family: PingFang;
        font-weight: 400;
        color: #828282;
        line-height: 24px;
        -webkit-line-clamp: 2; //设置几行
        display: -webkit-box; //设置为伸缩盒弹性盒子展示
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //设置超出部分以省略号展示
        -webkit-box-orient: vertical; //伸缩盒弹性盒子的排列方式
      }
    }
  }
}
</style>
