import request from "./request";
/**
 * 首页 index
 */
// 工业互联网标识应用
export function mgtApidList(data) {
  return request({
    url: "/mgtApid/list",
    method: "get",
    data,
  }).then(res => {
    return res.data
  });
}
// 解决方案
export function mgtSolnList(data) {
  return request({
    url: "/mgtSoln/list",
    method: "post",
    data,
  }).then(res => {
    return res.data
  });
}
// 新闻中心首页
export function mgtNewsList(data) {
  return request({
    url: "/mgtNews/list",
    method: "post",
    data,
  }).then(res => {
    return res.data
  });
}
// 新闻中心列表页
export function mgtNewsPage(data) {
  return request({
    url: "/mgtNews/page",
    method: "post",
    data,
  }).then(res => {
    return res.data
  });
}
// 新闻中心详情
export function newsForm(data) {
  return request({
    url: "/mgtNews/form",
    method: "post",
    data,
  }).then(res => {
    return res.data
  });
}
// 东数科技合作伙伴
export function mgtPrsList(data) {
  return request({
    url: "/mgtPrs/list",
    method: "get",
    data,
  }).then(res => {
    return res.data
  });
}
// 关于我们
export function mgtEntOpenEntInfo(data) {
  return request({
    url: "/mgtEnt/openEntInfo",
    method: "get",
    data,
  }).then(res => {
    return res.data
  });
}
// 导航栏
export function mgtNavList(data) {
  return request({
    url: "/mgtNav/list",
    method: "post",
    data,
  }).then(res => {
    return res.data
  });
}

// 底部 - 上部导航栏
export function mgtNavAllList(data) {
  return request({
    url: "/mgtNav/allList",
    method: "post",
    data,
  }).then(res => {
    return res.data
  });
}

