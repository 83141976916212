<template>
  <div class="page">
    <div class="application">
      <!-- <div class="secondaryTitle">
        解决方案
      </div>
      <div class="blueTiao"></div> -->
      <div class="main">
        <div class="top flex row-between" v-if="lsolnList.length" style="align-items: normal">
          <div
            class="top-left zz"
            @click="$router.push('/solnPageDetail?id=' + lsolnList[0].id)"
            :style="{ background: 'url(' + lsolnList[0].url + ') no-repeat' }"
          >
            <div class="title">
              <div>{{ lsolnList[0].title }}</div>
              <div class="describe">{{ lsolnList[0].prtk }}</div>
            </div>
          </div>
          <div class="top-right">
            <div
              class="top-right-box zz"
              @click="$router.push('/solnPageDetail?id=' + lsolnList[1].id)"
              :style="{ background: 'url(' + lsolnList[1].url + ') no-repeat' }"
            >
              <div class="title">
                <div>{{ lsolnList[1].title }}</div>
                <div class="describe">{{ lsolnList[1].prtk }}</div>
              </div>
            </div>
            <div
              class="top-right-box zz"
              @click="$router.push('/solnPageDetail?id=' + lsolnList[2].id)"
              :style="{ background: 'url(' + lsolnList[2].url + ') no-repeat' }"
            >
              <div class="title">
                <div>{{ lsolnList[2].title }}</div>
                <div class="describe">{{ lsolnList[2].prtk }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item flex row-start flex-wrap">
          <div
            v-for="(item, index) in asolnList"
            :key="index"
            @click="$router.push('/solnPageDetail?id=' + item.id)"
            class="box zz"
            :style="{ background: 'url(' + item.url + ') no-repeat' }"
          >
            <div class="title">
              <div>{{ item.title }}</div>
              <div class="describe">{{ item.prtk }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cooperate-page">
      <div class="cooperate">
        <div class="secondaryTitle">生态合作伙伴</div>
        <div class="blueTiao"></div>
        <div class="list flex flex-wrap">
          <div
            v-for="(item, index) in mgtPrsList"
            :key="index"
            class="list-item flex pointer"
            @click="toHerf(item.href)"
          >
            <img :src="item.nameUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mgtSolnList } from "../assets/api/solnPage";
import { mgtPrsList } from "../assets/api/index";

export default {
  name: "solnPage",
  data() {
    return {
      lsolnList: [],
      asolnList: [],
      mgtPrsList: [],
    };
  },

  mounted() {
    this.getMgtSolnList();
    this.getMgtPrsList();
  },
  methods: {
    toHerf(href) {
      window.open(href, '_blank')
    },
    getMgtSolnList() {
      mgtSolnList({}).then((res) => {
        this.lsolnList = res.object.slice(0, 3);
        this.asolnList = res.object.slice(3);
      });
    },
    // 东数科技合作伙伴
    getMgtPrsList() {
      mgtPrsList({}).then((res) => {
        this.mgtPrsList = res.object;
      });
    },
  },
};
</script>



<style lang="less" scoped>
.page {
  width: 100%;
  .application {
    width: 1200px;
    margin: 0 auto;
    margin-top: 103px;
    .top {
      .top-left {
        width: 602px;
        height: 579px;
        background-size: cover !important;
        background-attachment: scroll !important;
        padding: 30px;
        display: flex;
        align-items: flex-end;
        .title {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          z-index: 2;
        }
        .describe {
          margin-top: 18px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .top-right {
        width: 571px;
        .top-right-box {
          width: 571px;
          height: 280px;
          background-size: cover !important;
          background-attachment: scroll !important;
          margin-bottom: 19px;
          padding: 30px;
          display: flex;
          align-items: flex-end;
          .title {
            font-size: 26px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            z-index: 2;
          }
          .describe {
            margin-top: 18px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .item {
      .box {
        width: 385px;
        height: 342px;
        background-size: cover !important;
        background-attachment: scroll !important;
        padding: 30px;
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
        margin-right: 22px;
        .title {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          z-index: 2;
        }
        .describe {
          margin-top: 18px;
          font-size: 12px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .box:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .cooperate-page {
    width: 100%;
    margin-top: 137px;
    background: linear-gradient(-30deg, #f1f2fc, #f5f9fd);
    padding-bottom: 137px;
    .cooperate {
      width: 1200px;
      margin: 0 auto;
      padding-top: 103px;
      .list {
        .list-item {
          width: 200px;
          height: 114px;
          filter: grayscale(100%);
          img {
            width: auto;
            height: 44px;
          }
        }
        .list-item:hover {
          filter: grayscale(0%);
          background: #ffffff;
          box-shadow: 0px 6px 16px 0px rgba(145, 147, 148, 0.2);
          border-radius: 10px;
        }
      }
    }
  }
}
.zz {
  position: relative;
}
.zz::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000060;
}
.secondaryTitle {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 1;
  text-align: center;
  margin-bottom: 22px;
}
.blueTiao {
  width: 80px;
  height: 4px;
  background: linear-gradient(180deg, #0095cf, #0060fc);
  border-radius: 2px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-between {
  justify-content: space-between;
}
.row-start {
  justify-content: start;
}
.flex-wrap {
  flex-wrap: wrap;
}
</style>
