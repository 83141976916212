import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import Router from "@/router";
import {
  Input,
  Link,
  radioGroup,
  tabs,
  tabPane,
  pagination,
  carousel,
  carouselItem,
  image,
  dialog,
  Loading,
  Message
} from "element-ui";


import VueAMap from 'vue-amap';
Vue.use(VueAMap);
// 初始化高德地图的 key 和插件
VueAMap.initAMapApiLoader({
  key: '9ff19ea68f12eb0fe156780ffad5439d',
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor', // 圆形编辑器插件
    'AMap.Geocoder', // 地理编码与逆地理编码服务，用于地址描述与坐标间的相互转换
    "AMap.Geolocation" //定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  v: '1.4.4'
});

// 高德的安全密钥
window._AMapSecurityConfig = {
  securityJsCode: 'da57d4cca8f38f0a967c1f6fd13a7d88'
}


Vue.use(Loading.directive);
Vue.component(Input.name, Input);
Vue.component(Link.name, Link);
Vue.component(radioGroup.name, radioGroup);
Vue.component(tabs.name, tabs);
Vue.component(tabPane.name, tabPane);
Vue.component(pagination.name, pagination);
Vue.component(carousel.name, carousel);
Vue.component(carouselItem.name, carouselItem);
Vue.component(image.name, image);
Vue.component(dialog.name, dialog);
Vue.use(VueRouter);
Vue.prototype.$message = Message;
Vue.config.productionTip = false;

Vue.prototype.$loading = Loading.service;

Router.afterEach((to,from,next)=>{
  window.scrollTo(0,0);
})

new Vue({
  render: (h) => h(App),
  router: Router,
}).$mount("#app");