<template>
  <div class="search">
    <p class="text">查询结果</p>
    <div class="bsBox">
      <div class="title">标识分配信息</div>
      <div class="result-body" v-if="form.prefix != undefined">
        <div class="prefix-info">
          <p>标识信息</p>
          <div class="messBox">
            <span class="mt">前缀:</span
            ><span class="mage">{{ form.prefix || "-" }} </span>
          </div>
          <div class="messBox">
            <span class="mt">注册时间:</span
            ><span class="mage">{{ form.createDate || "-" }} </span>
          </div>
          <div class="messBox">
            <span class="mt">注册服务机构:</span
            ><span class="mage">{{ form.ghrName || "-" }} </span>
          </div>
          <div class="messBox">
            <span class="mt">注册状态:</span
            ><span class="mage"
              >{{ form.status == "1" ? "正常" : "异常" }}
            </span>
          </div>
        </div>
        <div class="prefix-info">
          <p>企业信息</p>
          <div class="messBox">
            <span class="mt">中文名称:</span
            ><span class="mage">{{ form.orgNameCn || "-" }} </span>
          </div>
          <div class="messBox">
            <span class="mt">官方网站:</span
            ><span class="mage">{{ form.orgSite || "-" }} </span>
          </div>
          <div class="messBox">
            <span class="mt">所属行业:</span
            ><span class="mage">{{ form.orgIndustry || "-" }} </span>
          </div>
          <div class="messBox">
            <span class="mt">中文地址:</span
            ><span class="mage">{{ form.orgAddr || "-" }} </span>
          </div>
        </div>
        <!-- <div class="prefix-info">
          <p>产品信息</p>
          <div class="messBox">
            <span class="mt">产品名称:</span><span class="mage">ADSS光缆 </span>
          </div>
          <div class="messBox">
            <span class="mt">产品标识:</span
            ><span class="mage">88.118.1/19-A2780 </span>
          </div>
          <div class="messBox">
            <span class="mt">产品描述:</span
            ><span class="mage">全介质自承式光缆 </span>
          </div>
        </div> -->
      </div>
      <div class="kong" v-else>暂无信息~</div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "searchPage",
  data() {
    return {
      form: null,
    };
  },
  watch: {
    $route(e) {
      this.searchFun(e.query.input);
    },
  },
  mounted() {
    this.searchFun(this.$route.query.input);
  },

  methods: {
    searchFun(val) {
      let that = this; //
      axios({
        url: "/api/inquire/home/inquire/prefix",
        params: {
          prefix: val,
        },
      })
        .then((res) => {
          if (res.status == 200) {
            that.form = res.data.data || {};
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped  lang="less">
.search {
  width: 680px;
  margin: 0 auto;
  .text {
    text-align: left;
    font-size: 12px;
    color: #999;
    padding: 38px 0 12px;
  }
  .bsBox {
    width: 100%;
    margin-bottom: 40px;
    -webkit-box-shadow: 0 2px 6px 0 hsla(0, 0%, 90.2%, 0.5);
    box-shadow: 0 2px 6px 0 hsla(0, 0%, 90.2%, 0.5);
    background-color: #fff;
    color: #666;
    border: 1px solid #fff;
    border-top: 4px solid #4d9dff;
    .title {
      color: #333;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      padding: 10px;
      margin: 0 30px;
      border-bottom: 1px solid #dedede;
    }
    .result-body {
      padding: 10px 20px;
      margin: 10px;
      //   background-image: url(../../static/img/Group\ 11.77bf7cf7.png);
      background-position: 100% 100%;
      background-repeat: no-repeat;
      .prefix-info {
        margin-bottom: 30px;
        p {
          margin-top: 0;
          font-weight: 700;
          padding-left: 28px;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAA30lEQVQ4T+2VvwrCMBDGvwOlu06F+ho+hGCHboKTfa66KI4uHXygSp3qrh1OUkxp2oQeATuZJQm5/PjuTy6E74iPfGHGTu8lMwGPWYD1dU93bU9qkZw5ql8oJBCLzTNPaWkA44xLBkJPIPKUGmFqNIttxuwLU/d+BlR5MBTOA6y6AR5TrT3TCtXedJlwyw+0GQP1E+kGSkgWm+mA/xiKU+RMii2GklckrkNp05iubMRB6xkOFPo01y5zAFSHPtBux05OHNZvlG1j9HW3/UIyLhiIrEBJqbgEuIAVgIWP6g9z5bUQPvWrQgAAAABJRU5ErkJggg==);
          background-repeat: no-repeat;
          background-position: 0;
          background-size: 20px;
          height: 20px;
          line-height: 20px;
          color: #333;
          margin-bottom: 14px;
        }
        .messBox {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding-bottom: 15px;
          .mt {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 125px;
            flex: 0 0 125px;
            text-align: right;
          }
          .mage {
            margin-left: 40px;
          }
        }
      }
    }
    .kong {
      text-align: center;
      font-size: 12px;
      color: #999;
      padding: 38px 0;
    }
  }
}
</style>