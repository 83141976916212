<template>
  <div class="page">
    <div class="productTry">
      <img class="bg" src="@/assets/product/bg1.jpg" />
      <div class="content" v-if="FromList.length">
        <div class="content-left">
          <div>
            {{ FromList[0].prtk }}
          </div>
          <!-- <div class="btn pointer">
            <span>免费试用</span>
          </div> -->
        </div>
        <div class="content-right">
          <img :src="FromList[0].url" alt="" />
        </div>
      </div>
    </div>
    <div class="platform">
      <div class="secondaryTitle">产品全生命周期追溯平台</div>
      <div class="blueTiao"></div>
      <div class="flex row-between">
        <div
          class="platform-l-box pointer"
          v-for="(item, index) in FromPlateList01"
          :key="index"
          @mouseenter="($event) => changeActive($event, index + 1)"
          @mouseleave="removeActive($event)" @click="$router.push('/productList?id=' + item.id)"
        >
          <img
            class="r-bg"
            style="width: 155px; top: -40px; right: -40px"
            src="@/assets/product/icon-bg2.png"
            alt=""
          />
          <div class="name">产品功能</div>
          <div class="content">
            <img :src="item.url" alt="" />
            <div>{{ item.title }}</div>
          </div>
          <div class="writing" v-if="active == index + 1">
            {{ item.prtk }}
          </div>
        </div>
      </div>
      <div
        class="platform-b-box pointer"
        v-for="(item, index) in FromPlateList02"
        :key="index"  @click="$router.push('/productList?id=' + item.id)"
      >
        <img
          class="r-bg"
          style="width: 155px; top: -40px; right: -40px"
          src="@/assets/product/icon-bg2.png"
          alt=""
        />
        <div class="name">产品功能</div>
        <div class="flex" style="height: 100%; justify-content: left">
          <div class="content-img">
            <img :src="item.url" alt="" />
          </div>
          <div class="content-title">
            <div>{{ item.title }}</div>
            <div class="describe">
              {{ item.prtk }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="application">
      <div class="secondaryTitle">在生产制造领域应用</div>
      <div class="blueTiao"></div>
      <div class="main">
        <img class="bg" src="@/assets/product/bg2.jpg" />
        <div class="content flex-wrap">
          <div
            class="content-item flex"
            style="justify-content: left"
            v-for="(item, index) in FromApidList"
            :key="index"
          >
            <div class="content-item-img">
              <img :src="item.url" alt="" />
            </div>
            <div class="content-item-title">
              <div>{{ item.title }}</div>
              <div class="content-item-describe">
                {{ item.prtk }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="use">
      <div class="secondaryTitle">使用场景</div>
      <div class="blueTiao"></div>
      <div class="main">
        <img class="bg" src="@/assets/product/bg3.jpg" />
        <div class="content">
          <el-tabs stretch v-model="useName" @tab-click="secondaryClick">
            <el-tab-pane
              :label="item.title"
              :name="'first' + index"
              v-for="(item, index) in FromSceneList"
              :key="index"
            >
            </el-tab-pane>
          </el-tabs>
          <div class="box">
            <div>{{ FromSceneForm.title }}</div>
            <div class="box-describe">
              {{ FromSceneForm.prtk }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mgtProductFromList,
  mgtProductFromPlateList,
  mgtProductFromApidList,
  mgtProductFromSceneList,
} from "../assets/api/product";

export default {
  name: "productCenter",
  data() {
    return {
      useName: "first0",
      FromList: [],
      FromPlateList01: [],
      FromPlateList02: [],
      FromApidList: [],
      FromSceneList: [],
      FromSceneForm: {},
      active: "0",
    };
  },
  mounted() {
    this.getmgtProductFromList();
    this.getmgtProductFromPlateList();
    this.getmgtProductFromApidList();
    this.getmgtProductFromSceneList();
  },
  methods: {
    secondaryClick(tab, event) {
      this.FromSceneForm = this.FromSceneList[tab.index];
    },
    getmgtProductFromList() {
      mgtProductFromList({}).then((res) => {
        this.FromList = res.object;
      });
    },
    getmgtProductFromPlateList() {
      mgtProductFromPlateList({}).then((res) => {
        this.FromPlateList01 = res.object.slice(0, 3);
        this.FromPlateList02 = res.object.slice(-2);
      });
    },
    getmgtProductFromApidList() {
      mgtProductFromApidList({}).then((res) => {
        this.FromApidList = res.object;
      });
    },
    getmgtProductFromSceneList() {
      mgtProductFromSceneList({}).then((res) => {
        this.FromSceneList = res.object;
        this.FromSceneForm = res.object[0];
      });
    },
    changeActive(e, index) {
      this.active = index;
    },
    removeActive(e) {
      this.active = "0";
    },
  },
};
</script>



<style lang="less" scoped>
/deep/.el-tabs__header {
  width: 1200px;
  height: 79px;
  background: #f8f9fc;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 58px;
  box-shadow: 0px 6px 16px 0px rgba(145, 147, 148, 0.35);
}
/deep/.el-tabs__nav-wrap::after {
  background-color: transparent;
}
/deep/.el-tabs__content {
  margin-top: 117px;
}
.page {
  width: 100%;
  .productTry {
    width: 100%;
    height: 545px;
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      z-index: -2;
    }
    .content {
      position: absolute;
      width: 1200px;
      left: calc(50% - 600px);
      top: 65px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content-left {
        width: 487px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 37px;
        color: #757373;
        .btn {
          width: 207px;
          height: 45px;
          margin: 0 auto;
          margin-top: 50px;
          line-height: 45px;
          text-align: center;
          color: #fff;
          background: linear-gradient(-30deg, #0194cb, #005afc);
        }
      }
      .content-right {
        width: 499px;
        height: 442px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .platform {
    width: 1200px;
    margin: 0 auto;
    padding-top: 96px;
    .platform-l-box,
    .platform-b-box {
      width: 377px;
      height: 216px;
      background: #f8f9fc;
      box-shadow: 0px 6px 16px 0px rgba(169, 171, 189, 0.35);
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      .writing {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 10;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #757373;
        padding-left: 30px;
        padding-top: 43px;
        padding-right: 43px;
        background: #f8f9fc;
      }
      .content {
        padding-top: 23px;
        padding-left: 44px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        img {
          width: 74px;
          margin-bottom: 17px;
          margin-left: 9px;
        }
      }
      .name {
        position: absolute;
        right: 0;
        top: 0;
        width: 100px;
        height: 36px;
        line-height: 36px;
        background: linear-gradient(150deg, #a2abf4, #5916ea);
        border-radius: 10px;
        border-bottom-right-radius: 0;
        color: #fff;
        text-align: center;
      }
      .r-bg {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .platform-b-box {
      width: 1200px;
      height: 248px;
      margin-top: 30px;
      .content-img {
        padding: 0 33px;
        img {
          width: 383px;
        }
      }
      .content-title {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        .describe {
          width: 630px;
          margin-top: 24px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #757373;
        }
      }
    }
    .platform-l-box:hover,
    .platform-b-box:hover {
      box-shadow: 0px 6px 16px 0px rgba(88, 104, 228, 0.35);
    }
  }
  .application {
    padding-top: 123px;
    width: 100%;
    height: auto;
    .main {
      margin-top: 67px;
      position: relative;
      height: 737px;
      .bg {
        width: 100%;
        height: 100%;
        z-index: -2;
      }
      .content {
        position: absolute;
        width: 1200px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .content-item {
          width: 589px;
          height: 146px;
          background: #f8f9fc;
          border-radius: 10px;
          margin-bottom: 27px;
          box-shadow: 0px 6px 16px 0px rgba(169, 171, 189, 0.35);
          .content-item-img {
            width: 100px;
            filter: grayscale(100%);
            padding-left: 28px;
            margin-right: 27px;
            img {
              width:100%;
            }
          }
          .content-item-title {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
          .content-item-describe {
            margin-top: 24px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #757373;
          }
        }
        .content-item:hover {
          box-shadow: 0px 6px 16px 0px rgba(88, 104, 228, 0.35);
          .content-item-img {
            filter: grayscale(0%);
          }
        }
      }
    }
  }
  .use {
    padding-top: 92px;
    width: 100%;
    height: auto;
    .main {
      margin-top: 67px;
      height: 769px;
      position: relative;
      .bg {
        width: 100%;
        height: 100%;
        z-index: -2;
      }
      .content {
        position: absolute;
        width: 1200px;
        left: calc(50% - 600px);
        top: 91px;
        .box {
          width: 1155px;
          margin: 0 auto;
          background: #f8f9fc;
          box-shadow: 0px 6px 16px 0px rgba(145, 147, 148, 0.35);
          border-radius: 10px;
          padding: 80px 108px;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          .box-describe {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #757373;
            line-height: 39px;
            margin-top: 46px;
          }
        }
      }
    }
  }
}

.secondaryTitle {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 1;
  text-align: center;
  margin-bottom: 22px;
}
.blueTiao {
  width: 80px;
  height: 4px;
  background: linear-gradient(180deg, #0095cf, #0060fc);
  border-radius: 2px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-between {
  justify-content: space-between;
}
.flex-wrap {
  flex-wrap: wrap;
}
</style>
