<template>
  <div class="bottomImgDiv">
    <div class="ctop">
      <div class="ctopLeft">
        <div class="ctBox" v-for="(item, index) in leftList" :key="index">
          <div class="btnTitle">{{ item.title }}</div>
          <div
            class="btnMess"
            v-for="(item02, index02) in item.messList"
            :key="index02"
            @click="jumpTo(index, item02)"
          >
            {{ item02.title }}
          </div>
        </div>
      </div>
      <div class="shuxian"></div>
      <div class="ctopRight">
        <div class="ctrTitle">业务咨询</div>
        <div class="lxBoox">
          <img src="../assets/images/phone.png" alt="" /><span>{{
            usForm.phone
          }}</span>
        </div>
        <div class="lxBoox">
          <img src="../assets/images/email.png" alt="" /><span>{{
            usForm.email
          }}</span>
        </div>
        <div class="lxBoox">
          <img src="../assets/images/addres.png" alt="" /><span>{{
            usForm.addr
          }}</span>
        </div>
        <div class="ctrTitle">关于我们</div>
        <div class="ewm">
          <div class="ewmBox" style="margin-right: 20px">
            <img :src="usForm.url" alt="" />
            东数科技
          </div>
          <div class="ewmBox">
            <img :src="usForm.gzhQrCode" alt="" />
            东数科技
          </div>
        </div>
      </div>
    </div>
    <div class="cBot">
      <div class="href" style="margin-bottom: 20px">
        友情链接：<span class="pointer" @click="toHerf(item.href)" v-for="(item, index) in hrefList" :key="index">{{
          item.name
        }}</span>
      </div>
      <div class="href">
        CopyRight @ 2020 东数科技 版权所有
        工业互联网应用服务商 吉ICP备20002226号-6
      </div>
    </div>
  </div>
</template>

<script>
import { mgtEntOpenEntInfo, mgtNavList, mgtNavAllList } from "../assets/api/index";

export default {
  name: "BusinessBottom",
  data() {
    return {
      leftList: [],
      hrefList: [
        { title: "工业互联网标识解析", href: "" },
        { title: "中国信息通信研究院", href: "" },
        { title: "工业互联网产业联盟", href: "" },
        { title: "工业互联网标识解析", href: "" },
      ],
      usForm: {},
    };
  },
  created() {
    if (sessionStorage.getItem("aboutUs") == null) {
      this.getMgtEntOpenEntInfo();
    } else {
      this.usForm = JSON.parse(sessionStorage.getItem("aboutUs"));
    }
    if(JSON.parse(sessionStorage.getItem("hrefList"))) {
      this.hrefList = JSON.parse(sessionStorage.getItem("hrefList"));
    } else {
      this.getMgtNavList()
    }
    if(JSON.parse(sessionStorage.getItem("leftList"))) {
      this.leftList = JSON.parse(sessionStorage.getItem("leftList"));
    } else {
      this.getMgtNavAllList()
    }
  },
  mounted() {},

  methods: {
    jumpTo(type, info) {
      switch(type) {
        case 0:
          this.$router.push('/twoAnal')
          break;
        case 1:
          this.$router.push('/solnPageDetail?id=' + info.id)
          break;
        case 2:
          this.$router.push('/articleDetail?id=' + info.id)
          break;
        case 3:
          this.$router.push('/ent')
          break;
        default: 
      } 
    },
    toHerf(href) {
      window.open(href, '_blank')
    },
    getMgtEntOpenEntInfo() {
      mgtEntOpenEntInfo({}).then((res) => {
        this.usForm = res.object;
        sessionStorage.setItem("aboutUs", JSON.stringify(this.usForm));
      });
    },
    getMgtNavList() {
      mgtNavList({ type: "1" }).then((res) => {
        this.hrefList = res.object;
        sessionStorage.setItem("hrefList", JSON.stringify(this.hrefList));
      });
    },
    getMgtNavAllList() {
      mgtNavAllList({ page: { size: 6 } }).then((res) => {
        const arr = [
        {
          title: "二级节点"
        },
        {
          title: "解决方案"
        },
        {
          title: "新闻资讯"
        },
        {
          title: "关于我们",
          messList: [
            {
              title: '公司简介',
              href: '/ent'
            },
            {
              title: '发展历程',
              href: '/ent'
            },
            {
              title: '联系我们',
              href: '/ent'
            },
          ]
        },
        ]
        arr[0].messList = res.body.twoAnal.object;
        arr[1].messList = res.body.soln.object;
        arr[2].messList = res.body.news.object;
        this.leftList = arr;
        sessionStorage.setItem("leftList", JSON.stringify(this.leftList));
      });
    }
  },
};
</script>

<style scoped  lang="less">
.bottomImgDiv {
  position: relative;
  background: #141c1e;
  padding-bottom: 10px;
  min-width: 1200px;
  box-sizing: border-box;
  .ctop {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    margin-bottom: 30px;
    .ctopLeft {
      display: flex;
      padding-top: 10px;
      width: 856px;
      .ctBox {
        flex: 1;
        .btnTitle {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 34px;
          margin-bottom: 18px;
        }
        .btnMess {
          width: 80%;
          font-size: 10px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #818282;
          line-height: 34px;
          cursor: pointer;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    .shuxian {
      width: 1px;
      height: 284px;
      background: #717373;
    }
    .ctopRight {
      width: 320px;
      padding-left: 81px;
      padding-top: 10px;
      box-sizing: content-box;
      .ctrTitle {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 34px;
        margin-bottom: 10px;
        padding-left: 6px;
      }
      .lxBoox {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        img {
          width: 20px;
          height: 26px;
          display: block;
          margin-right: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 0;
          padding-left: 6px;
        }
        span {
          flex: 1;
          font-size: 10px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 2;
        }
      }
      .ewm {
        display: flex;

        .ewmBox {
          font-size: 8px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #818282;
          line-height: 32px;
          text-align: center;
          img {
            width: 75px;
            height: 75px;
            display: block;
            background-color: #fff;
          }
        }
      }
    }
  }
  .cBot {
    .href {
      font-size: 8px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #818282;
      line-height: 1;
      text-align: center;
      span {
        padding: 0 14px;
        border-right: 1px solid #818282;
      }
      span:first-child {
        padding-left: 0;
      }
      span:last-child {
        border-right: none;
      }
    }
  }
}
</style>
