<template>
  <div class="outDiv">
    <div class="nav">
      <div class="tittle">
        <div class="logo">
          <img src="../assets/images/logo.png" alt="" />
        </div>
        <div style="width: 618px">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              v-for="(item, index) in navList"
              :key="index"
              :label="item.name"
              :name="item.href"
              @click="$router.push(item.href)"
              >{{ item.name }}</el-tab-pane
            >
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="banDiv" :style="{ height }">
      <el-carousel
        trigger="click"
        :height="height"
        :interval="5000"
        :indicator-position="bannerList.length <= 1 ? 'none' : ' '"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img class="bannerImg" :src="item.url" alt="" />
          <div v-if="activeName == '/twoAnal'" class="search">
            <div class="ssBox">
              <div class="s1">节点标识</div>
              <div class="s2"></div>
              <div class="s3">
                <el-input v-model="input" placeholder="请输入内容"></el-input>
              </div>
            </div>

            <div class="s4" @click="search">搜索</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { mgtNavList } from "../assets/api/index";
// import imgindexUrl from "@assets/images/img.png";
export default {
  name: "BusinessHeader",
  data() {
    return {
      input: "",
      height: "0px",
      content: "",
      activeName: this.$route.path,
      bannerList: [],
      // imgindexUrl: imgindexUrl,
      navList: [],
    };
  },
  watch: {
    $route(e) {
      this.input = "";
      this.activeFun(e.path);
      this.upload();
    },
  },
  mounted() {
    this.activeFun(this.$route.path);
    const that = this;
    if (sessionStorage.getItem("nav") == null) {
      this.getmgtNavList();
    } else {
      this.upload();
    }
    this.height = document.body.clientWidth * 0.4419 + "px";
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        that.height = document.body.clientWidth * 0.4419 + "px";
      })();
    };
  },

  methods: {
    search() {
      this.input != ""
        ? this.$router.push("/searchPage?input=" + this.input)
        : "";
    },
    activeFun(data) {
      if (data == "/articleDetail" || data == "/newsList") {
        this.activeName = "/news";
      } else if (data == "/solnPageDetail") {
        this.activeName = "/solnPage";
      } else if (data == "/searchPage") {
        this.activeName = "/twoAnal";
      } else if (data == "/productList") {
        this.activeName = "/product";
      } else {
        this.activeName = data;
      }
    },
    upload() {
      this.navList = JSON.parse(sessionStorage.getItem("nav"));
      for (let i = 0; i < this.navList.length; i++) {
        if (this.activeName == this.navList[i].href) {
          this.bannerList = this.navList[i].slideshowList || [];
        }
      }
    },
    handleClick(tab, event) {
      this.$router.push(this.navList[tab.index].href);
      this.bannerList = this.navList[tab.index].slideshowList || [];
    },
    getmgtNavList() {
      this.$loading();
      mgtNavList({ type: "0" }).then((res) => {
        this.navList = res.object;
        sessionStorage.setItem("nav", JSON.stringify(this.navList));
        for (let i = 0; i < this.navList.length; i++) {
          this.navList[i].slideshowList.forEach((v) => {
            let image = new Image();
            image.src = v.url;
          });
          if (this.activeName == this.navList[i].href) {
            this.bannerList = this.navList[i].slideshowList || [];
          }
        }
        this.$loading().close();
      });
    },
  },
};
</script>

<style scoped  lang="less">
/deep/ .el-carousel__button {
  width: 30px;
  height: 3px;
  opacity: 0.35;
  background: #41aaaa;
}
.outDiv {
  position: relative;
  min-width: 1200px;
}
.banDiv {
  height: 640px;
  position: relative;
  background: url(http://39.103.141.238:81/files/9a884ce2-9b4a-4605-bb55-73b499ce3345.png)
    no-repeat;
  background-size: 100% 100%;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #204d81;
}
.bannerImg {
  width: 100%;
  height: 100%;
}
/deep/ .el-input__inner {
  border: none;
  padding: 0 20px;
}
.search {
  position: absolute;
  color: red;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 555px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ssBox {
    display: flex;
    align-items: center;
    .s1 {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      padding: 0 20px 0 16px;
      line-height: 40px;
    }
    .s2 {
      width: 2px;
      height: 26px;
      background: linear-gradient(0deg, #50bebe, #049bca);
      border-radius: 1px;
    }
  }
  .s4 {
    width: 88px;
    height: 100%;
    background: linear-gradient(0deg, #0068f5, #049bca);
    border-radius: 7px;
    line-height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    letter-spacing: 4px;
    cursor: pointer;
  }
}
.nav {
  width: 100%;
  height: 72px;
  margin-top: -4px;
  position: absolute;
  top: 0;
  z-index: 999;
  border: 1px solid #ffffff15;
}
.tittle {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  width: 160px;
  height: 36px;
  img {
    width: 100%;
    height: 100%;
  }
}

/deep/ .el-tabs {
  height: 72px;
}
/deep/ .el-tabs__header {
  margin: 0;
}
/deep/ .el-tabs__nav {
  height: 72px;
}
/deep/ .el-tabs__item {
  font-size: 19px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #ffffff !important;
  line-height: 72px;
  height: 72px;
}
/deep/ .el-tabs__content {
  display: none;
}
/deep/ .el-tabs__nav-wrap::after {
  background-color: #ffffff00;
  height: 0;
}
/deep/ .el-tabs__active-bar {
  height: 3px;
  background: #41b7c0;
  border-radius: 2px;
}
</style>
