<template>
  <div id="app">
    <Header />
    <div class="app-view">
      <router-view />
    </div>
    <Bottom />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Bottom from "./components/bottom";

export default {
  name: "App",
  components: {
    Header,
    Bottom,
  },
};
</script>

<style>
#app {
  width: 100%;
  min-width: 1200px;
}
.app-view {
  min-height: 500px;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  /* background-color: #F7F7F7; */
}
.pointer {
  cursor:pointer;
}
.hoverBlue:hover {
  color: #409EFF !important;
}
</style>
