import request from "./request";
// 新闻资讯
export function mgtNewsList(data) {
  return request({
    url: "/mgtNews/list",
    method: "post",
    data,
  }).then(res => {
    return res.data
  });
}
